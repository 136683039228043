<template>

  <div v-if="loading">

    Lütfen bekleyiniz...

  </div>

  <div v-else class="row">

    <div class="col-lg-6">
      <div class="input-wrap">
        <label>{{ t('enterYourAppName') }}</label>
        <input
          class="form-control"
          :value="formState.store_name"
          type="text"
          disabled
          :placeholder="t('storeName')"
        />
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-wrap">
        <label>{{ t('storeId') }}</label>
        <input
          class="form-control"
          :value="formState.store_id"
          type="text"
          disabled
          :placeholder="t('storeId')"
        />
      </div>
    </div>
  </div>
  <div class="row">

    <div class="col-lg-6">
      <div class="input-wrap">
        <label>{{ t('storeLangs') }}</label>
        <a-select
          v-model:value="store.langs"
          mode="multiple"
          :value="store.langs.map((lang) => lang.code)"
          style="width: 100%"
          :placeholder="t('storeLangs')"
        >
          <a-select-option
            :value="item.id"
            :label="item.name"
            v-for="item in currentLanguages"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

    </div>
    <!--    <div class="col-lg-6">-->
    <!--      <ImageInput v-model="image" :label="t('storeLogo')"/>-->
    <!--      <img :src="store.profile?.store_logo_link" alt=""/>-->
    <!--    </div>-->
    <div class="col-lg-6">
      <div class="input-wrap">
        <label>{{ t('storeAppLink') }}</label>
        <input class="form-control" v-model="formState.app_link" type="text"/>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-wrap">
        <label>{{ t('storeTermsLink') }}</label>
        <input class="form-control" v-model="formState.terms_link" type="text"/>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="btnSet right">
        <button class="btn btn-primary" @click="onSubmit">
          {{ t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useGlobal from '../../composables/useGlobal'
import { onMounted, reactive, ref } from 'vue'
import isoLangs from '../../service/isoLangs.json'

export default {
  setup() {
    const { t, restApi } = useGlobal()

    const loading = ref(false)
    const hasError = ref(false)
    const errorMessage = ref('')
    const formState = reactive({
      store_id: '',
      store_name: '',
      app_link: '',
      terms_link: '',
      store_logo_link: '',
    })

    const currentLanguages = ref(isoLangs)

    const store = ref({})

    const onSubmit = () => {

      restApi.editAboutUs(formState.app_link, formState.terms_link).then(response => {

        alert('güncelleme yapıldı')

      })

    }

    onMounted(() => {

      loading.value = true

      restApi.fetchAboutUs().then((response) => {

        formState.store_id = response.getStoreId()
        formState.store_name = response.getStoreName()
        formState.app_link = response.getAppLink()
        formState.terms_link = response.getTermsLink()
        formState.store_logo_link = response.getStoreLogoLink()

        console.log(formState)

        loading.value = false

      }).catch(error => {

        errorMessage.value = error.toString()
        hasError.value = true

      })

    })

    return {
      t,
      store,
      currentLanguages,

      loading,
      hasError,
      errorMessage,
      formState,

      onSubmit,
    }
  },
}
</script>
